import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import '@mdi/font/css/materialdesignicons.css'
import "@mdi/light-font/css/materialdesignicons-light.css";
import '@/assets/scss/app.scss'
Vue.use(Vuetify);

const opts = {
	theme: {
		themes: {
			light: {
				primary: "#221F20"
			}
		}
	}
};

export default new Vuetify(opts);